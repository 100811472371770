<template>
  <v-row justify="center" align="center" no-gutters class="mb-3">
    <v-col cols="12">
      <v-row justify="center">
        <div style="position: relative">
          <v-btn
            v-if="previewImage"
            color="error"
            fab
            x-small
            style="position: absolute; top: -10px; right: 10px; z-index: 1"
            @click="onDeleteImage()"
            ><v-icon>mdi-delete</v-icon>
          </v-btn>

          <vue-cropper
            ref="cropper"
            v-if="previewImage"
            :style="getTypeSize()"
            :src="previewImage"
            :cropBoxMovable="cropBoxMovable"
            :cropBoxResizable="cropBoxResizable"
            :minCropBoxHeight="getSizeCrop()"
            :minContainerHeight="getSizeCrop()"
            :viewMode="viewMode"
            :responsive="true"
            :background="false"
            :aspectRatio="aspectRatio"
            :initialAspectRatio="initialAspectRatio"
            :crop="getCropImage"
            :zoomOnTouch="true"
            class="mx-5 mb-3"
            dragMode="move"
            @cropmove="cropMove"
          >
          </vue-cropper>

          <label
            v-else
            :style="getTypeSize()"
            :for="id"
            id="dropContainer"
            class="image-undefined"
          >
            <v-icon x-large color="grey lighten-1">mdi-plus</v-icon>
          </label>
        </div>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-file-input
        :loading="uploadFile && previewImage === null"
        :rules="[v => !v || v.size < maxSize || 'Maksimal foto adalah 5 MB']"
        :id="id"
        accept="image/*"
        v-model="originalImage"
        :label="$t('app.photo')"
        prepend-icon=""
        outlined
        show-size
        hide-details
        dense
        @change="onFileChange"
        :disabled="isEdit"
      ></v-file-input>
    </v-col>
    <v-col cols="12" v-if="originalImage">
      <v-row justify="center" class="mt-2">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              text
              icon
              dense
              @click="$refs.cropper.relativeZoom(0.1)"
              ><v-icon>mdi-magnify-plus</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("components.zoom_in") }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              text
              icon
              dense
              @click="$refs.cropper.relativeZoom(-0.1)"
              ><v-icon>mdi-magnify-minus</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("components.zoom_out") }}</span>
        </v-tooltip>

        <v-divider vertical></v-divider>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              text
              icon
              dense
              @click="$refs.cropper.rotate(-90)"
              ><v-icon>mdi-rotate-left</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("components.rotate_left") }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              text
              icon
              dense
              @click="$refs.cropper.rotate(90)"
            >
              <v-icon>mdi-rotate-right</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("components.rotate_right") }}</span>
        </v-tooltip>

        <v-divider vertical></v-divider>

        <v-menu open-on-hover offset-x right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text icon dense>
              <v-icon v-bind="attrs" v-on="on">
                mdi-information
              </v-icon>
            </v-btn>
          </template>
          <v-card flat class="pa-2 elevation-0">
            <ul>
              <li>
                {{ $t("components.slider_info1") }}
              </li>
              <li>
                {{ $t("components.slider_info2") }}
              </li>
            </ul>
          </v-card>
        </v-menu>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export default {
  components: { VueCropper },
  props: {
    cropedImage: {
      type: Function
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    value: String,
    cropBoxMovable: {
      type: Boolean,
      default: false
    },
    cropBoxResizable: {
      type: Boolean,
      default: false
    },
    viewMode: {
      type: Number,
      default: 0
    },
    clearForm: Boolean,
    id: {
      type: String,
      default: "fileFoto"
    },
    typeSize: {
      type: String,
      default: "potrait"
    },
    aspectRatio: {
      type: Number,
      default: 1 / 1
    },
    initialAspectRatio: {
      type: Number,
      default: 1 / 1
    }
  },
  data() {
    return {
      maxSize: 5000000,
      originalImage: null,
      previewImage: null,
      uploadFile: false,
      zoomValue: 0
    };
  },
  watch: {
    value(newVal) {
      this.previewImage = newVal;
      this.getImageName();
    },
    clearForm(value) {
      if (value) this.originalImage = null;
    }
  },
  created() {
    if (this.value) {
      this.previewImage = this.value;
    }
  },
  methods: {
    getTypeSize() {
      let size = "";
      switch (this.typeSize.toLowerCase()) {
        case "landscape-footer":
          size = "height: 130px; width: 200px";
          break;
        case "landscape":
          size = "height: 200px; width: 320px";
          break;
        case "potrait":
          size = "height: 200px; width: 150px";
          break;
        case "square":
          size = "height: 150px; width: 150px";
          break;
        default:
          size = "height: 200px; width: 150px";
          break;
      }
      return size;
    },
    getSizeCrop() {
      let result = 200;
      switch (this.typeSize.toLowerCase()) {
        case "square":
          result = 150;
          break;
        case "landscape-footer":
          result = 120;
          break;
      }
      return result;
    },
    getImageName() {
      this.$emit("getImageName", this.originalImage);
    },
    async onFileChange() {
      this.previewImage = null;
      if (this.originalImage && this.originalImage.size <= this.maxSize) {
        this.uploadFile = true;
        if (this.originalImage) {
          const convertToBase64 = await toBase64(this.originalImage);

          if (convertToBase64) {
            setTimeout(() => {
              this.previewImage = convertToBase64;
              this.uploadFile = false;
            }, 1000);
          }
        } else {
          this.cropedImage(null);
        }
      } else {
        this.onDeleteImage();
        this.originalImage = null;
        this.uploadFile = false;
      }
    },
    onDeleteImage() {
      this.$emit("deleteImage");
    },
    getCropImage() {
      if (this.$refs.cropper) {
        const croped = this.$refs.cropper.getCroppedCanvas().toDataURL();
        this.cropedImage(croped);
      }
    },
    cropMove(data) {
      this.$emit("cropMove", data);
    }
  }
};
</script>

<style lang="scss" scoped>
.image-undefined {
  border: 1px solid lightgrey;
  border-radius: 5px;
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-wrap: wrap;
}

.cropper-view-box {
  border: 1px solid lightgrey !important;
  border-radius: 5px !important;
  outline: none !important;
}
.cropper-modal {
  background-color: transparent !important;
}
</style>
